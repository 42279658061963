.game-card {
  &.clickable {
    img {
      cursor: pointer;
    }
  }

  img {
    width: 200px;

    @media (max-width: $breakpoint-mobile) {
      width: 100px;
    }
  }
}
