.app-header {
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: $color-background-secondary;

  > .commup {
    cursor: pointer;
    padding: 0px 5px;
    display: flex;
    flex-direction: row;
    align-items: center;

    > .title {
      margin-left: 10px;
      color: $color-white;
    }

    > img {
      cursor: pointer;
      width: 50px;
    }
  }

  a, span {
    font-size: 20px;
    color: $color-white;
    cursor: pointer;
    text-decoration: none;
  }

  > .links {
    padding-inline-start: 0px;
    display: flex;
    flex-direction: row;

    li {
      list-style: none;
      margin: 10px;
    }
  }
}
