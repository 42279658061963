.app {
  background-color: $color-background;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  > .content {
    max-width: $content-max-width;
    width: 100%;
    align-self: center;
    display: flex;
    flex-direction: column;
    padding: 20px 10px;
  }
}

html,
body,
#root {
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  background-color: $color-background;
  height: 100%;
  width: 100%;
  margin: 0;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
