.page-sign-in {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  width: 100%;
  max-width: $content-max-width-small;

  > .header {
    color: $color-white;
  }

  > .component-input {
    width: 100%;
    margin: 10px 5px;
  }

  > .component-button {
    width: 100%;
    margin: 10px 5px;
  }

  a {
    font-size: 20px;
    color: $color-primary;
  }
}
