.page-game-list {
  > .title {
    color: $color-white;
  }

  > .game-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));

    @media (max-width: $breakpoint-mobile) {
      grid-template-columns: repeat(auto-fill, minmax(105px, 1fr));
    }
  }
}
