.username-app-header {
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: $color-background-secondary;

  > .commup {
    padding: 0px 5px;
    display: flex;
    flex-direction: row;
    align-items: center;

    > .title {
      margin-left: 10px;
      color: $color-white;
    }

    > img {
      width: 50px;
    }
  }
}
