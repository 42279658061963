.component-input {
  border: 3px solid white;
  background-color: $color-background-secondary;
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  padding: 10px;

  //border-bottom: 3px solid $color-background-secondary;

  color: white;

  &.selected {
    border-color: $color-primary;

    > .label {
      color: $color-primary;
    }
  }

  > input {
    border: none;
    background: none;
    outline: none;
    margin-top: 10px;
    padding: 0px;
    font-size: 18px;
    color: white;
  }

  > .error {
    margin-top: 10px;
    color: $color-error;
  }
}
