.component-button.disabled {
  filter: brightness(65%);
  cursor: inherit;
}

.component-button.loading {
  cursor: inherit;
}

@mixin button-mixin {
  padding: 20px;
  cursor: pointer;
  outline: none;
  border: none;
  background-color: $color-primary;

  font-size: 1em;
  font-weight: bold;

  &.size-small {
    font-size: 0.8em;
    padding: 10px 20px;
  }
}

button.component-button {
  @include button-mixin;

  border-radius: 3px;
}

a.component-button {
  border-radius: 3px;
  text-align: center;
  text-decoration: none;

  > button {
    @include button-mixin;
  }
}
