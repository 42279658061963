.page-home {
  display: flex;
  flex-direction: column;
  align-items: center;

  > .title {
    color: $color-white;
  }

  > .sub-title {
    color: $color-white;
  }

  > img {
    margin-top: 10px;
    width: 100%;
    max-width: 300px;
  }

  > .component-button {
    margin-top: 30px;
    width: 100%;
    > * {
      width: 100%;
      max-width: 300px;
    }
  }
}
